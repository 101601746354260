<template>
    <div class="row review-component">
        <b-card class="col-lg-10 offset-lg-1">
            <div class="panel-list">

                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Amend Employee Info')">
                                <b-img class="edit-icon" :src="editIcon" /> EMPLOYEE/REQUEST TYPE INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>EMPLOYEE PHONE NUMBER</label>
                            <p v-if="!MediaVendorAmend.EmployeePhoneNumber">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.EmployeePhoneNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ENTITY</label>
                            <p>{{ MediaVendorAmend.EntityName }}</p>
                            <p v-if="!MediaVendorAmend.EntityName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>AGENCY</label>
                            <p v-if="MediaVendorAmend.AgencyName">
                                {{ MediaVendorAmend.AgencyName }}
                            </p>
                            <p v-if="!MediaVendorAmend.AgencyName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>System Type</label>
                            <p v-if="MediaVendorAmend.VendorTypeSystem">
                                {{ MediaVendorAmend.VendorTypeSystem }}
                            </p>
                            <p v-if="!MediaVendorAmend.VendorTypeSystem">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Vendor Media Type</label>
                            <p v-if="MediaVendorAmend.VendorTypeMedia">
                                {{ MediaVendorAmend.VendorTypeMedia }}
                            </p>
                            <p v-if="!MediaVendorAmend.VendorTypeMedia">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Code/Name Type</label>
                            <p v-if="MediaVendorAmend.VendorTypeCode">
                                {{ MediaVendorAmend.VendorTypeCode }}
                            </p>
                            <p v-if="!MediaVendorAmend.VendorTypeCode">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>What is changing</label>
                            <b-col cols="lg-12 px-0">
                                <p v-if = "MediaVendorAmend.IsRemittanceNameAddressChanged == false && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.AreThereConditionsOrLimitations == 'true' == false">N/A</p>
                                <p>
                                    <span v-if="MediaVendorAmend.IsRemittanceNameAddressChanged == true">Remittance Name/Address</span>
                                    <span v-if="MediaVendorAmend.IsRemittanceNameAddressChanged == true && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.AreThereConditionsOrLimitations == 'true' == true">
                                        &
                                    </span>
                                    <span v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.AreThereConditionsOrLimitations == 'true' == true">Banking Info</span>
                                </p>
                            </b-col>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Is this Request for a Media Rep?</label>
                            <p v-if="!MediaVendorAmend.IsMediaRepRequest">
                                N/A
                            </p>
                            <p v-if="MediaVendorAmend.IsMediaRepRequest == 'true'">
                                Yes
                            </p>
                            <p v-if="MediaVendorAmend.IsMediaRepRequest == 'false'">
                                No
                            </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Amend Setup Info')">
                                <b-img class="edit-icon" :src="editIcon" /> SETUP INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>VENDOR CODE</label>
                            <p>
                                {{ MediaVendorAmend.VendorCode }}
                            </p>
                            <p v-if="!MediaVendorAmend.VendorCode">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>VENDOR Business Name</label>
                            <p>
                                {{ MediaVendorAmend.VendorBusinessName }}
                            </p>
                            <p v-if="!MediaVendorAmend.VendorBusinessName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                            <label>INVOICE ATTACHMENT</label>
                            <div v-if="MediaVendorAmend.InvoiceAttachment.length > 0" class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendorAmend.InvoiceAttachment" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendorAmend.InvoiceAttachment, MediaVendorAmend.Files, 'InvoiceAttachment')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                            <label>VENDOR REMITTANCE NAME</label>
                            <p v-if="!MediaVendorAmend.VendorRemittanceName">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.VendorRemittanceName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                            <label>COUNTRY</label>
                            <p>
                                {{ MediaVendorAmend.RemittanceCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                            <label>STREET ADDRESS LINE 1</label>
                            <p>
                                {{ MediaVendorAmend.StreetAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                            <label>STREET ADDRESS LINE 2</label>
                            <p>
                                {{ MediaVendorAmend.StreetAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == CountryUSA">
                            <label>CITY</label>
                            <p>
                                {{ MediaVendorAmend.RemittanceCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == CountryUSA">
                            <label>STATE</label>
                            <p>
                                {{ MediaVendorAmend.RemittanceState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == CountryUSA">
                            <label>ZIP CODE</label>
                            <p>
                                {{ MediaVendorAmend.RemittanceZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING CONTACT NAME</label>
                            <p v-if="!MediaVendorAmend.AccountingContactName">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.AccountingContactName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING CONTACT TITLE</label>
                            <p v-if="!MediaVendorAmend.AccountingContactTitle">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.AccountingContactTitle }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING PHONE #</label>
                            <p v-if="!MediaVendorAmend.AccountingPhone">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.AccountingPhone }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING EMAIL ADDRESS</label>
                            <p v-if="!MediaVendorAmend.AccountingEmailAddress">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.AccountingEmailAddress }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>How was this information obtained?</label>
                            <p v-if="!MediaVendorAmend.InfoFromSourceIsVerbal && !MediaVendorAmend.InfoFromSourceIsWritten">
                                N/A
                            </p>
                            <p>
                                <span v-if="MediaVendorAmend.InfoFromSourceIsVerbal">Verbal</span>
                                <span
                                    v-if="MediaVendorAmend.InfoFromSourceIsVerbal && MediaVendorAmend.InfoFromSourceIsWritten">
                                    &
                                </span>
                                <span v-if="MediaVendorAmend.InfoFromSourceIsWritten">Written</span>
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.InfoFromSourceVerbal">
                            <label>Verbal</label>
                            <p>
                                {{ MediaVendorAmend.InfoFromSourceVerbal }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.InfoFromSourceWritten">
                            <label>Written</label>
                            <p>
                                {{ MediaVendorAmend.InfoFromSourceWritten }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.InfoFromSourceWritten">
                            <label>Written Communication Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendorAmend.SetupInfoSource" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendorAmend.SetupInfoSource, MediaVendorAmend.Files, 'SetupInfoSource')">Download</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="panel-content">
                        <b-col cols="lg-12">
                            <label>Who at the vendor provided this information?</label>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Name</label>
                            <p>
                                {{ MediaVendorAmend.InfoFromName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Title</label>
                            <p>
                                {{ MediaVendorAmend.InfoFromTitle }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Telephone Number</label>
                            <p>
                                {{ MediaVendorAmend.InfoFromPhone }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Has there been a remittance name change for this vendor or a change in ownership?</label>
                            <p v-if="MediaVendorAmend.IdentificationOption == true || MediaVendorAmend.IdentificationOption == 'true'">
                               Yes
                            </p>
                            <p v-if="MediaVendorAmend.IdentificationOption == false || MediaVendorAmend.IdentificationOption == 'false'">
                               No
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>IDENTIFICATION TYPE</label>
                            <p v-if="!MediaVendorAmend.IdentificationType">
                                N/A
                            </p>
                            <p v-if="MediaVendorAmend.IdentificationType === 'SocialSecurityNumber'">SOCIAL SECURITY NUMBER
                            </p>
                            <p v-if="MediaVendorAmend.IdentificationType === 'USTaxId'">US Tax ID</p>
                            <p v-if="MediaVendorAmend.IdentificationType === 'Foreign'">Foreign</p>

                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IdentificationType">
                            <label>IDENTIFICATION NUMBER</label>
                            <p v-if="!MediaVendorAmend.IdentificationType">
                                N/A
                            </p>
                            <p>
                                {{formatIdentityType(MediaVendorAmend[MediaVendorAmend.IdentificationType]) }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IdentificationType">
                            <label>{{ AttachmentLabel }}</label>
                            <p v-if="(!MediaVendorAmend.IdentificationFiles || MediaVendorAmend.IdentificationFiles.length == 0 )">
                                N/A
                            </p>
                            <div v-else class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendorAmend.IdentificationFiles" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendorAmend.IdentificationFiles, MediaVendorAmend.Files, 'IdentificationFiles')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>DUN & BRADSTREET NUMBER</label>
                            <p v-if="!MediaVendorAmend.DunAndBradstreetNumber">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.DunAndBradstreetNumber }}
                            </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Amend Credit Card Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Credit Card/Banking Info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?</label>
                            <p v-if="MediaVendorAmend.IsCreditCardAccepted == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendorAmend.IsCreditCardAccepted == 'false'">
                                NO
                            </p>
                            <p v-if="!MediaVendorAmend.IsCreditCardAccepted">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsCreditCardAccepted == 'false'">
                            <label>Would the vendor like to be set up by ACH Payment?</label>
                            <p v-if="MediaVendorAmend.IsACHPayment == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendorAmend.IsACHPayment == 'false'">
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsACHPayment == 'true'">
                            <label>Is Banking Info Collected</label>
                            <p v-if="MediaVendorAmend.IsBankingInfoCollected == 'true'">YES</p>
                            <p v-if="MediaVendorAmend.IsBankingInfoCollected == 'false'">NO</p>
                            <p v-if="!MediaVendorAmend.IsBankingInfoCollected">N/A</p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoCollected == 'true'">
                            <label>Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendorAmend.CreditCardFiles" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendorAmend.CreditCardFiles, MediaVendorAmend.Files, 'CreditCardFiles')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsCreditCardAccepted == 'true'">
                            <label>Are there fees or conditions/limitations in accepting payment via credit card for this organization? An example would be that the vendor charges a 3% fee on all transactions, or that the companies credit card information must be held on file with the vendor for immediate payment processing.</label>
                            <p v-if="MediaVendorAmend.AreThereConditionsOrLimitations == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendorAmend.AreThereConditionsOrLimitations == 'false'">
                                NO
                            </p>
                            <p v-if="!MediaVendorAmend.AreThereConditionsOrLimitations">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.AreThereConditionsOrLimitations == 'true'">
                            <label>What are the Limitations?</label>
                            <p v-if="!MediaVendorAmend.CreditLimitation">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendorAmend.CreditLimitation }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"  v-if="MediaVendorAmend.AreThereConditionsOrLimitations == 'true'">
                            <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                            <p v-if="!MediaVendorAmend.HasACHPaymentSetup">
                                N/A
                            </p>
                            <p v-if="MediaVendorAmend.HasACHPaymentSetup == 'true'">
                                YES
                            </p>
                            <p v-else>
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4"  v-if="MediaVendorAmend.HasACHPaymentSetup == 'true'">
                            <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                            <p v-if="!MediaVendorAmend.HasBankLetterInvoice">
                                N/A
                            </p>
                            <p v-if="MediaVendorAmend.HasBankLetterInvoice == 'true'">
                                YES
                            </p>
                            <p v-else>
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>BANK NAME</label>
                            <p>
                                {{ MediaVendorAmend.BankName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>BANK ADDRESS LINE 1</label>
                            <p>
                                {{ MediaVendorAmend.BankAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>BANK ADDRESS LINE 2</label>
                            <p v-if="!MediaVendorAmend.BankAddressLine2">
                                N/A
                            </p>
                            <p v-if="MediaVendorAmend.BankAddressLine2">
                                {{ MediaVendorAmend.BankAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.BankCountry && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>COUNTRY</label>
                            <p>
                                {{ MediaVendorAmend.BankCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.BankCountry == CountryUnitedStatesValue  && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>STATE</label>
                            <p>
                                {{ MediaVendorAmend.BankState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendorAmend.BankCountry == CountryUnitedStatesValue && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>CITY</label>
                            <p>
                                {{ MediaVendorAmend.BankCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendorAmend.BankCountry == CountryUnitedStatesValue && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>ZIP CODE</label>
                            <p>
                                {{ MediaVendorAmend.BankZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>ACCOUNT NAME</label>
                            <p>
                                {{ MediaVendorAmend.AccountName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>ACCOUNT NUMBER</label>
                            <p>
                                {{ MediaVendorAmend.AccountNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendorAmend.BankCountry != CountryUnitedStatesValue && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>Sort/Swift (Non US)</label>
                            <p>
                                {{ MediaVendorAmend.BankBIC }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendorAmend.BankCountry != CountryUnitedStatesValue && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>IBAN (Non US)</label>
                            <p>
                                {{ MediaVendorAmend.BankIBAN }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendorAmend.BankCountry == CountryUnitedStatesValue && MediaVendorAmend.IsBankingInfoChanged && MediaVendorAmend.HasBankLetterInvoice == 'true'">
                            <label>ROUTING NUMBER (US ONLY)</label>
                            <p>
                                {{ MediaVendorAmend.RoutingNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.HasBankLetterInvoice == 'true' && MediaVendorAmend.IsBankingInfoChanged">
                            <label>REMITTANCE ADVICE EMAIL</label>
                            <p>
                                {{ MediaVendorAmend.RemittanceAdviceEmail }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.HasBankLetterInvoice == 'true' && MediaVendorAmend.IsBankingInfoChanged">
                            <label>BANK LETTER OR INVOICE</label>
                            <div v-if="MediaVendorAmend.BankLetter.length > 0" class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendorAmend.BankLetter" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendorAmend.BankLetter, MediaVendorAmend.Files, 'BankLetter')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Amend Employee Relationship')">
                                <b-img class="edit-icon" :src="editIcon" /> Employee Relationship
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content" v-if="MediaVendorAmend.EmployeeVendorRelationship.length > 0">

                        <template v-if="!IsNoneSelected">
                            <b-col cols="lg-4" v-for="item in MediaVendorAmend.EmployeeVendorRelationship" :key="item">
                                <label>{{ GetRelationshipLabel(item) }}</label>
                                <div>{{ MediaVendorAmend.EmployeeVendorRelationshipDetails[item] }}</div>
                            </b-col>
                        </template>
                        <b-col cols="lg-4" v-if="IsNoneSelected">
                            <label>Employee Relationship With Vendor</label>
                            <p>None</p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Has any form of compensation or gifts of any type been offered to you or any person
                                related to you in exchange for placing an order with this vendor?</label>
                            <p v-if="MediaVendorAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder == 'true'">YES</p>
                            <p v-else>NO</p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendorAmend.CompensationReceived">
                            <label>Compensation Received</label>
                            <p>${{ MediaVendorAmend.CompensationReceived }}</p>
                        </b-col>
                    </b-row>

                </div>
            </div>
            <div class="d-block col-lg-10 agree-checkbox">
                <b-form-checkbox value="true" v-model="MediaVendorAmend.Attestation">By checking this box, the requestor is
                    confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the
                    propriety of the vendor and confirm that the information contained on the form is accuraate to the best
                    of their knowledge</b-form-checkbox>
            </div>
        </b-card>
        <b-form-group class="footer-buttons text-right">
            <button class="btn custom-button btn-link" @click="Cancel()">
                Cancel
            </button>
            <button class="btn custom-button btn-primary" v-if="CanGoToSection" @click="Previous()">
                BACK
            </button>

            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="!Edit" @click="Submit()">
                Submit
            </button>
            <button class="btn custom-button btn-primary"  :disabled="!IsFormSigned" v-if="CanResubmit && !isDraft" @click="Resubmit()">
                Resubmit
            </button>
        </b-form-group>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import store from "@/store";
import downloadFile from '../../../mixins/downloadFile';
import * as status from "../../../utilities/VendorWorkflowStates";
export default {
    mixins: [downloadFile],
    data() {
        return {
            CountryUSA: "USA - United States",
            SpecialRequirement: null,
            AddSpecialbtn: true,
            openspecialreqbox: false,
            EnableSpecialReqEdit: false,
            editIcon: require("@/assets/Icons/edit.svg"),
            CountryUnitedStatesValue: "USA - United States",
            addIcon: require("@/assets/Icons/add.svg"),
            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        ...mapGetters('vendormediaamend', {
            MediaVendorAmend: 'MediaVendorAmend',
        }),
        CreditCardFiles() {
            let files = [];
            if (this.MediaVendorAmend.Files.length > 0) {
                return this.MediaVendorAmend.Files.filter(file => file.Category == "CreditCardFiles");
            }
            return files;
        },
        IdentificationFiles() {
            let files = [];
            if (this.MediaVendorAmend.Files.length > 0) {
                return this.MediaVendorAmend.Files.filter(file => file.Category == "IdentificationFiles");
            }
            return files;
        },
        SetupInfoSource() {
            let files = [];
            if (this.MediaVendorAmend.Files.length > 0) {
                return this.MediaVendorAmend.Files.filter(file => file.Category == "SetupInfoSource");
            }
            return files;
        },
        BankLetter() {
            let files = [];
            if (this.MediaVendorAmend.Files.length > 0) {
                return this.MediaVendorAmend.Files.filter(file => file.Category == "BankLetter");
            }
            return files;
        },
        InvoiceAttachment() {
            let files = [];
            if (this.MediaVendorAmend.Files.length > 0) {
                return this.MediaVendorAmend.Files.filter(file => file.Category == "InvoiceAttachment");
            }
            return files;
        },
        IsFormSigned() {
            return this.MediaVendorAmend.Attestation == true || this.MediaVendorAmend.Attestation == 'true';
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        AttachmentLabel() {
            return this.MediaVendorAmend.IdentificationType == 'SocialSecurityNumber' || this.MediaVendorAmend.IdentificationType == 'USTaxId' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        IsNoneSelected() {
            return this.MediaVendorAmend.EmployeeVendorRelationship.length == 1 && this.MediaVendorAmend.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        CanResubmit() {
            return (
                this.id &&
                parseInt(this.id) > 0 &&
                this.MediaVendorAmend.Status == status.Canceled
            );
        },
        Edit() {
            return this.id && parseInt(this.id) > 0 && !this.isDraft;
        },
        CanGoToSection() {
            return !this.IsEdit || this.CanResubmit;
        },
        GetInfoSource() {
            let text = "";
            if (this.MediaVendorAmend.infofromsourceisverbal) {
                text += "Verbal"
            }
            if (this.MediaVendorAmend.infofromsourceiswritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        }
    },
    activated() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    created() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },

    watch: {

    },
    filters: {

    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.MediaVendorAmend.IdentificationType == "USTaxId") {
                    return "US Tax ID";
                } else if (this.MediaVendorAmend.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.MediaVendorAmend.IdentificationType == "USTaxId" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.MediaVendorAmend.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return "N/A";
            }
        },
        ConvertToBool(mediaAmendInfo) {
            mediaAmendInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder = mediaAmendInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder == "true" ? true : false;
            mediaAmendInfo.IsCreditCardAccepted = mediaAmendInfo.IsCreditCardAccepted == "true" ? true : false;
            mediaAmendInfo.AreThereConditionsOrLimitations = mediaAmendInfo.AreThereConditionsOrLimitations == "true" ? true : false;
            mediaAmendInfo.UrlSpecifiedInForm = mediaAmendInfo.UrlSpecifiedInForm == "true" ? true : false;
            mediaAmendInfo.IsMediaRepRequest = mediaAmendInfo.IsMediaRepRequest == "true" ? true : false;
            mediaAmendInfo.IdentificationOption = mediaAmendInfo.IdentificationOption == "true" ? true : false;
            mediaAmendInfo.IsBankingInfoCollected = mediaAmendInfo.IsBankingInfoCollected == "true" ? true : false;
            mediaAmendInfo.IsACHPayment = mediaAmendInfo.IsACHPayment == "true" ? true : false;
        },
        PrepareEmployeeVendorRelationship(mediaAmendInfo) {
            if (this.IsNoneSelected) {
                let employeeRelationship = {
                    ID: 0,
                    RelationshipName: 'NONE'
                };
                mediaAmendInfo.EmployeeVendorRelationship = [];
                mediaAmendInfo.EmployeeVendorRelationship.push(employeeRelationship);
                mediaAmendInfo.EmployeeVendorRelationshipDetails = null;
            } else {
                let employeeRelationships = [];
                mediaAmendInfo.EmployeeVendorRelationship.forEach(employeeRelations => {
                    employeeRelationships.push({
                        ID: 0,
                        RelationshipName: employeeRelations
                    });
                })
                mediaAmendInfo.EmployeeVendorRelationship = [];
                mediaAmendInfo.EmployeeVendorRelationship = employeeRelationships;

            }
        },
        PrepareData(IsResubmit) {
            let mediavendoramendInfo = Object.assign({}, this.MediaVendorAmend);
            this.ConvertToBool(mediavendoramendInfo);
            this.PrepareEmployeeVendorRelationship(mediavendoramendInfo);
            if (IsResubmit == true) {
                mediavendoramendInfo.Files = [];
                this.GetFilesCategory().forEach(name => {
                    this.AttachUpdatedFiles(mediavendoramendInfo.Files, name);
                });
            }
            mediavendoramendInfo.CreditCardFiles = [];
            this.MediaVendorAmend.CreditCardFiles.forEach(s => {
                mediavendoramendInfo.CreditCardFiles.push(s.name);
            });
            return mediavendoramendInfo;
        },
        SaveAsDraft() {
            let data = this.PrepareData(true);
            let formData = new FormData();
            let files = this.$refs.myVueDropzone.dropzone.files;
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('request', JSON.stringify(data));
            // formData.append('totalFiles', JSON.stringify(data.Files.map(val => {
            //     return val.Name
            // })));
            formData.append('draftRequestId', this.isDraft == true ? parseInt(this.id) : 0);
            this.$store
                .dispatch("wiretransfer/SaveAsDraft", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your wire transfer request has been saved as draft!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while saving wire transfer request as draft. ${ex}`
                        );
                    }
                });
        },
        AttachFiles(formData, fileCategory) {
            let files = this.MediaVendorAmend[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`${fileCategory}[${i}]`, file.file || file);
            }
        },
        AttachUpdatedFiles(filesArray, fileCategory) {
            let files = this.MediaVendorAmend[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
            }
        },
        GetFilesCategory() {
            return ["IdentificationFiles", "SetupInfoSource", "BankLetter", "InvoiceAttachment", "CreditCardFiles"];
        },
        Submit() {
            let formData = new FormData();
            let data = this.PrepareData();
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("vendormediaamend/AddRequestWithFiles", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Amend Media Vendor request has been submitted!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating media vendor request. ${ex}`
                        );
                    }
                });
        },
        Resubmit() {
            let data = this.PrepareData(true);
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            let formData = new FormData();
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("vendormediaamend/UpdateRequestWithFiles", {
                    id: parseInt(this.id),
                    data: formData
                })
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your media vendor request has been updated!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while updating wire transfer request. ${ex}`
                        );
                    }
                });
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Amend Employee Relationship",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Amend Employee Relationship"
                });
            }
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },

        GotoSection(name) {
            if (this.id) {
                this.$router.push({
                    name: name,
                    params: {
                        id: parseInt(this.id)
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
            // this.$router.push(`${section}`);
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },

    },
};
</script>

<style scoped>
span.btn.btn-primary.btn-view {
    height: 0;
    width: 94px;
    min-width: 94px;
    padding: 0;
    margin: 0;
    min-height: 22px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
